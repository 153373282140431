:root {
  --primary: #4fc3f7;
  --primaryLight: #81d4fa;
  --primaryDark: #039be5;
  --background: #333;
  --text: #eee;
  box-sizing: border-box;
  color: var(--text);
  font-family: "Russo One";
}

%section-width {
  box-sizing: border-box;
  @media screen and (max-width: 1080px) {
    article {
      box-sizing: border-box;
      width: 100%;
      margin: 2rem 0;
      padding: 0 1rem;
      text-align: justify;
    }
  }
  @media screen and (min-width: 1081px) {
    article {
      width: 1000px;
      margin: 3rem auto;
    }
  }
}

%icon-size {
  @media screen and (max-width: 1080px) {
    width: 64px;
    margin: 0.5rem 1rem;
  }
  @media screen and (min-width: 1081px) {
    width: 128px;
    margin: 1rem 2rem;
  }
}

%timeline-template {
  @media screen and (max-width: 1080px) {
    display: flex;
    position: relative;
    flex-direction: column;
    & .TheLine {
      position: absolute;
      left: 50%;
      z-index: 0;
      bottom: 0rem;
      top: -0.75rem;
    }
    .Company {
      border-top-right-radius: 0;
      border-top-left-radius: 0;
      margin: 3rem 0;
      &::after {
        position: absolute;
        right: -1px;
        left: -1px;
        top: -2rem;
        background: #4fc3f7;
        border-top-right-radius: 0.5rem;
        border-top-left-radius: 0.5rem;
        content: attr(aria-label);
        font-size: 1rem;
        font-weight: 600;
        height: 2rem;
        display: flex;
        align-items: center;
        justify-content: center;
        color: var(--background);
      }
    }
    .Background {
      border-top-left-radius: 0;
      border-top-right-radius: 0;

      &::after {
        position: absolute;
        right: -1px;
        top: -2rem;
        left: -1px;
        height: 2rem;
        border-top-left-radius: 0.5rem;
        border-top-right-radius: 0.5rem;
        background-color: var(--background);
        display: flex;
        align-items: center;
        justify-content: center;
        content: attr(aria-label);
        font-size: 0.9rem;
        font-weight: 600;
        color: var(--primary);
      }
    }
  }
  @media screen and (min-width: 1081px) {
    $lines: 5;
    display: grid;
    grid-template-columns: 50% 0.25rem 50%;
    @for $i from 1 through $lines {
      & .Company:nth-child(#{$i}) {
        grid-row-start: $i;
        grid-row-end: $i + 1;
      }
      & .Background:nth-child(#{$i}) {
        grid-row-start: $i;
        grid-row-end: $i + 1;
      }
    }
    & .TheLine {
      grid-column-start: 2;
      grid-column-end: 3;
      grid-row-start: 1;
      grid-row-end: $lines + 1;
    }
  }
}

%header-template {
  @media screen and (max-width: 1080px) {
    width: 100vw;
    .Side {
      display: flex;
      font-size: 0.75rem;
      margin: 0 1vw;
    }
    img {
      height: 8vh;
    }
    .Contact {
      margin-top: 1rem;
      img {
        margin: 0 0.5rem;
        height: 2rem;
      }
    }
  }
  @media screen and (min-width: 1081px) {
    .Side {
      font-size: 2rem;
    }
    img {
      height: 10vh;
    }
    .Contact {
      margin-top: 1rem;
      img {
        margin: 0 1rem;
        height: 3rem;
      }
    }
  }
  
}

h1 {
  font-style: italic;
}

@media screen and (max-width: 1080px) {
  h1 {
    line-height: 2rem;
    font-size: 1.25rem;
    text-align: center;
  }
}
@media screen and (min-width: 1081px) {
  h1 {
    line-height: 3rem;
    font-size: 1.75rem;
    text-align: justify;

  }
}


h2 {
  font-size: 2rem;
}

h3 {
  font-size: 1.5rem;
}

h4 {
  font-size: 1.25rem;
  margin: 0;
}
h5 {
  font-size: 1.1rem;
}

#main {
  position: relative;
  transform: scale(200%)
}

#face {
  &.isDocked {
    padding-top: 50vh;
  }
  width: 100vw;
  padding-top: 100vh;
  z-index: 0;
}

.App {
  overflow-x: hidden;
  background-color: var(--background);
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  overflow-y: scroll;
}

.Header {
  &.isDocked {
    position: sticky;
    box-shadow: 0px 1px 10px 5px #000;
    height: 25vh;
    min-height: 15rem;
    #main {
      transform: scale(100%)
    }
  }
  @extend %header-template;
  position: absolute;
  box-shadow: none;
  background-color: var(--background);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 2vh 0;
  top: 0;
  bottom: 0;
  height: 100vh;
  z-index: 1;
  width: 100vw;
  box-sizing: border-box;
  div {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .Contact {
      display: flex;
      flex-direction: row;
      pointer-events: auto;
      a {
        z-index: 10;
      }
      img {
        cursor: pointer;
        fill: #eee;
      }
    }
  }
  
}

.Section {
  @extend %section-width;
  &:nth-child(odd) {
    background: var(--primaryDark);
    .Timeline {
      .TheLine {
        background-color: var(--background);
      }
    }
  }
  &:nth-child(even) {
    background: var(--background);
    .Timeline {
      .TheLine {
        background-color: var(--text);
      }
    }
  }
  h2 {
    margin-top: 0;
    text-shadow: 1px 1px 1px #000;
    text-align: center;
  }
  &:first-of-type {
  }
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.Portfolio {
  img {
    @extend %icon-size;
    aspect-ratio: 1/1;
    background-color: #fff;
    object-fit: contain;
    border: 1px solid #fff;
    box-shadow: 0 0 15px 1px #000;

    cursor: pointer;
  }
  &::-webkit-scrollbar {
    display: none;
  }
  display: flex;
  overflow-x: auto;
  scrollbar-width: none; 
}

.Main {
  z-index: 0;
}

.Background, .Project {
  a {
    text-decoration: none;
  }
  background-color: var(--text);
  border: 1px solid var(--background);
  padding: 1rem;
  border-radius: 0.5rem;
  margin: 2rem 0;
  z-index: 1;
  position: relative;

  label, div {
    font-size: 0.7rem;
    display: block;
    color: var(--background);
  }
  h5 {
    margin: 0;
    margin-bottom: 0.25rem;
    color: var(--primary);
  }
}

.Company {
  margin: 2rem;
  position: relative;
  padding: 1rem;
  z-index: 1;
  background-clip: padding-box; /* !importanté */
  $border: 1px;
  border: solid 1px var(--primary); /* !importanté */
  border-radius: 0.5rem;
  background-color: var(--background);
  label {
    font-size: 0.7rem;
    margin-bottom: 0.5rem;
    display: block;
  }
  h5 {
    margin: 0;
    color: var(--primary);
  }
  div {
    text-align: justify;
    font-size: 0.8rem;
    line-height: 1.1rem;
  }
  .About {
  }
  & .Accomplishments {
    position: relative;
    margin-top: 2rem;
    ul {
      margin: 0;
      margin-left: 1rem;
      padding: 0;
    }
    li::marker {
      content: "✓ ";
      font-weight: 700;
    }
    &:before {
      position: absolute;
      content: "Tasks and responsibilities";
      top: -1.25rem;
      left: 0;
      font-size: 0.8rem;
      font-weight: 700;
    }
  }

  .TechStack {
    margin: 0;
    margin-top: 1rem;
    padding: 0;
    padding-top: 1rem;
    position: relative;
    &:before {
      position: absolute;
      top: 0;
      left: 0;
      content: "Tech Stack";
      font-size: 0.75rem;
      font-weight: 700;
    }
    & li {
      list-style-type: none;
      float: left;
      margin: 0;
      margin-right: 0.25rem;
      margin-top: 0.25rem;
      padding: 0.25rem 0.5rem;
      background-color: var(--primary);
      color: var(--background);
      border-radius: 0.25rem;
      font-size: 0.7rem;
      font-weight: 600;
    }
  }
}

.Timeline {
  @extend %timeline-template;

  & .TheLine {
    width: 0.25rem;
    border-radius: 0.25rem;
    height: 100%;
    content: "";
    background-color: #222;
  }

  @media only screen and (min-width: 1080px) {
    & :is(.Company):nth-child(even) {
      grid-column-start: 1;
      grid-column-end: 2;
      border-top-right-radius: 0;
      box-shadow: 0px 0px 5px 1px #222;
      &::after {
        position: absolute;
        right: -4rem;
        top: -1px;
        box-shadow: 2px 0px 5px 1px #222;

        background: #4fc3f7;
        border-top-right-radius: 2.5rem;
        border-bottom-right-radius: 2.5rem;
        content: attr(aria-label);
        font-size: 0.75rem;
        font-weight: 600;
        width: 4rem;
        height: 3rem;
        display: flex;
        align-items: center;
        justify-content: center;
        color: var(--background);
      }
    }

    & :is(.Company):nth-child(odd) {
      grid-column-start: 3;
      grid-column-end: 4;
      border-top-left-radius: 0;
      box-shadow: 0px 0px 5px 1px #222;
      &::after {
        position: absolute;
        left: -4rem;
        top: -1px;
        background: #4fc3f7;
        border-top-left-radius: 2.5rem;
        border-bottom-left-radius: 2.5rem;
        content: attr(aria-label);
        font-size: 0.75rem;
        font-weight: 600;
        width: 4rem;
        height: 3rem;
        display: flex;
        align-items: center;
        justify-content: center;
        color: var(--background);
        box-shadow: -2px 0px 5px 1px #222;
      }
    }

    & :is(.Background):nth-child(odd) {
      border-top-right-radius: 2rem;
      border-bottom-right-radius: 2rem;
      grid-column-start: 1;
      grid-column-end: 2;
      margin-right: -3rem;
      &::after {
        position: absolute;
        right: 1rem;
        top: 0;
        bottom: 0;
        display: flex;
        flex-direction: column;
        justify-content: center;
        content: attr(aria-label);
        font-size: 0.9rem;
        font-weight: 600;
        color: var(--primary);
      }
    }

    & :is(.Background):nth-child(even) {
      border-top-left-radius: 2rem;
      border-bottom-left-radius: 2rem;
      grid-column-start: 3;
      grid-column-end: 4;
      margin-left: -3rem;
      text-align: right;
      &::after {
        position: absolute;
        left: 1rem;
        top: 0;
        bottom: 0;
        display: flex;
        flex-direction: column;
        justify-content: center;
        content: attr(aria-label);
        font-size: 0.9rem;
        font-weight: 600;
        color: var(--primary);
      }
    }
  }
}
